import { BASE_URL as PRODUCTION_BASE_URL, HOST as PRODUCTION_HOST } from '../.env.production.js';
import { BASE_URL as DEVELOPMENT_BASE_URL, HOST as DEVELOPMENT_HOST } from '../.env.development.js';

const BASE_URL = (process.env.NODE_ENV === 'development' ? DEVELOPMENT_BASE_URL : PRODUCTION_BASE_URL);
const HOST = (process.env.NODE_ENV === 'development' ? DEVELOPMENT_HOST : PRODUCTION_HOST);

if (!BASE_URL) {
    throw new Error('BASE_URL is not defined');
} else if (!HOST) {
    throw new Error('HOST is not defined');
}

export const apiUrl = BASE_URL;
export const host = HOST;
export const version = "1";
export const applicationUrl = window.location.origin;