// https://vuex.vuejs.org/en/actions.html
import * as TYPES from "./types";
import { http } from "../plugins/http";
import * as env from '../config'

export default {
  getDistrictUsers () {
    return new Promise((resolve, reject) => {
      http({
        url: `get_district_users`,
        method: 'get'
      })
        .then(response => resolve(response.data.data))
        .catch(error => reject(error))
    })
  },
  generateAndSaveReport (store, data) {
    return new Promise((resolve, reject) => {
      http({
        url: 'requests/generate-and-save-report',
        method: 'post',
        data: {
          filters: data.filters,
          requestId: data.requestId
        }
      })
        .then(response => resolve(response.data.data))
        .catch(error => reject(error))
    })
  },
  getChildSavedReports (store, payload) {
    return new Promise((resolve, reject) => {
      http({
        url: `wide-reports/get-child-reports/${payload.id}`,
        method: 'get'
      })
        .then(response => resolve(response.data.data))
        .catch(error => reject(error))
    })
  },
  removeDistrictWideParticipant (store, payload) {
    return new Promise((resolve, reject) => {
      http({
        url: 'wide-reports/remove-district-wide-participant',
        method: 'POST',
        data: {
          requestId: payload.requestId,
          participantId: payload.participantId
        }
      })
        .then(response => resolve(response.data.data))
        .catch(error => reject(error))
    })
  },
  getRequestById (store, id) {
    return new Promise((resolve, reject) => {
      http({
        url: `requests/${id}`,
        method: 'get'
      })
        .then(response => resolve(response.data.data))
        .catch(error => reject(error))
    })
  },
  /**
   * @param store
   * @param payload {Object}
   * @param payload.year {Number} - The year parameter is a number to get reports by created date.
   * @param payload.perPage {Number} - The number of saved reports to receive.
   * @param payload.reportType {String} - The type of report.
   * @param payload.sortKey {String} - The flag indicates which field needs to sort.
   * @param payload.sortFlag {Boolean} - The flag indicates how to sort the results. ASC or DESC.
   * @param payload.isMultipleSchools {Boolean} - The flag indicates how to get saved reports. Reports with multiple schools or single school.
   * @param payload.s {String} - The parameter for search by Report name.
   *
   * @returns {Promise<unknown>}
   */
  getRequests (store, payload) {
    const year            = undefined === payload.year || null === payload.year.key ? '' : `&year=${payload.year.key}`
    const perPage         = undefined === payload.perPage ? 4 : payload.perPage
    const typeReport      = undefined === payload.reportType ? '' : `typeReport=${payload.reportType}&`
    const sortBy          = undefined === payload.sortKey ? '' : `&sortKey=${payload.sortKey}`
    const sortFlag        = undefined === payload.sortFlag ? '' : `&sortFlag=${payload.sortFlag ? 1 : 0}`
    const multipleSchools = undefined === payload.isMultipleSchools ? '' : `&ms=${payload.isMultipleSchools ? 1 : 0}`
    const s               = undefined === payload.s ? '' : `&s=${payload.s}`
    return new Promise((resolve, reject) => {
      http({
        url: `requests?${typeReport}page=${payload.page}&perPage=${perPage}${sortBy}${sortFlag}${multipleSchools}${year}${s}`,
        method: 'get'
      })
        .then(response => resolve(response.data.data))
        .catch(error => reject(error))
    })
  },
  updateRequest (store, data) {
    return new Promise((resolve, reject) => {
      http({
        url: `requests/${data.id}`,
        method: 'POST',
        data: {
          filters: data.filters,
          name: data.name,
          _method: 'put'
        }
      })
        .then(response => resolve(response.data.data))
        .catch(error => reject(error))
    })
  },
  deleteSavedRequest (store, id) {
    return new Promise((resolve, reject) => {
      http({
        url: `requests/${id}`,
        method: 'DELETE'
      })
        .then(response => resolve(response.data.data))
        .catch(error => reject(error))
    })
  },
  getUserReports(store, id) {
    return new Promise((resolve, reject) => {
      http({
        url: `report/${id}`,
        method: 'GET'
      })
        .then(response => resolve(response.data.data))
        .catch(error => reject(error))
    })
  },
  getInitializationData(store, obj) {
    return new Promise((resolve) => {
      http({
        url: 'get_initialization_data',
        method: 'POST',
        data: obj
      })
        .then(response => {
          // store.commit('report/SET_COURSES', { courses: response.data.initData.initializationData.courses})
          store.commit('report/SET_MEASUREMENT_TYPES', { measurementTypes: response.data.measurementTypes})
          store.commit('report/SET_DATASCORES', { dataScores: response.data.dataSources})
          store.commit('report/setDistrictTerms', response.data.districtTerms)
          resolve(response)
        })
    })
  },
  authUser(store, obj) {
    return new Promise((resolve, reject) => {
      http({
        url: 'sign_in_token?redirectUrl=' + obj.redirectUrl,
        method: 'GET',
      })
        .then(response => resolve(response.data.data))
        .catch(error => reject(error))
    })
  },
  getUserByToken(store, obj) {
    return new Promise((resolve, reject) => {
      http({
        url: 'get_user?authToken=' + obj.token,
        method: 'GET'
      })
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },
  setFetching({ commit }, obj) {
    commit(TYPES.MAIN_SET_FETCHING, obj);
  },
  setMessage({ commit }, obj) {
    commit(TYPES.MAIN_SET_MESSAGE, obj);
  },
  resetMessages({ commit }) {
    commit(TYPES.MAIN_SET_MESSAGE, { type: "success", message: "" });
    commit(TYPES.MAIN_SET_MESSAGE, { type: "error", message: [] });
    commit(TYPES.MAIN_SET_MESSAGE, { type: "warning", message: "" });
    commit(TYPES.MAIN_SET_MESSAGE, { type: "validation", message: [] });
  },
  logout(store) {
    store.commit('SET_USER', {user: null})
    store.commit('SET_TOKEN', {token: null})
    localStorage.removeItem('reportTypeRedirect')
    localStorage.removeItem('userToken')
    localStorage.removeItem('user')
    window.location.href = env.host
    // router.push({path: 'generate-report'})
  }
};
